import React from 'react';
import image1 from '../images/image 2.png';
import image2 from '../images/image 3.png';
import image3 from '../images/image 4.png';
import image4 from '../images/image 6.png';
import image5 from '../images/image 7.png';
import image6 from '../images/image 8.png';
import image7 from '../images/image 5.png';
import image8 from '../images/image 9.png';
import { useNavigate } from 'react-router-dom';

const Catalog = () => {
  const navigate = useNavigate();
const handleCardClick = (id) => {
    switch (id) {
      case 1:
        navigate('/catalog1');
        break;
      case 2:
        navigate('/catalog1');
        break;
      case 3:
        navigate('/catalog1');
        break;
      case 4:
        navigate('/catalog1');
        break;
      case 5:
        navigate('/catalog1');
        break;
      case 6:
        navigate('/catalog1');
        break;
      case 7:
        navigate('/catalog1');
        break;
      case 8:
        navigate('/catalog1');
        break;
      default:
        break;
    }
  };
  const cardsData = [
    { id: 1, title: 'OUTDOOR <br/> DECOR', imageUrl: image1 },
    { id: 2, title: 'WALL <br/> DECOR', imageUrl: image2 },
    { id: 3, title: 'KITCHEN <br/> DECOR', imageUrl: image3 },
    { id: 4, title: 'BATHROOM <br/> DECOR', imageUrl: image4 },
    { id: 5, title: 'PRAYER <br/>  ESSENTIALS', imageUrl: image5 },
    { id: 6, title: 'WALL LIGHT <br/> DECOR', imageUrl: image6 },
    { id: 7, title: 'CORPORATE <br/>GIFTS', imageUrl: image7 },
    { id: 8, title: 'ACRYLIC <br/> FRAME', imageUrl: image8 }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mx-4 sm:mx-8 md:mx-20 mt-52">
      {cardsData.map((card) => (
        <div
          key={card.id}
          className="relative bg-gray-200 w-full h-52 sm:h-54 md:h-80 lg:h-82 lg:w-50 ml-3 cursor-pointer"
          onClick={() => handleCardClick(card.id)}
        >
          <img src={card.imageUrl} className="object-cover w-full h-full" alt={card.title} />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <h5
              style={{ fontFamily: 'Rosarivo' }}
              className="text-white text-center text-2xl pt-60"
              dangerouslySetInnerHTML={{ __html: card.title }}
            ></h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Catalog;

// import React, { useEffect, useState } from 'react';
// import { Link,useNavigate } from 'react-router-dom';

// const Catalog = () => {
//   const navigate = useNavigate();
//   const [cardsData, setCardsData] = useState([]);

//   useEffect(() => {
//     // Fetch catalog data from the API
//     const fetchCatalogs = async () => {
//       try {
//         const response = await fetch('https://hridayam.dasoclothings.in/api/getCatelogsforuser');
//         const result = await response.json();

//         if (result.status) {
        
//           const mappedData = result.data.data.map((item) => ({
//             id: item._id,
//             title: item.title,
//             imageUrl: item.image,
//           }));
//           setCardsData(mappedData);
//         } else {
//           console.error('Failed to retrieve catalogs:', result.message);
//         }
//       } catch (error) {
//         console.error('Error fetching catalogs:', error);
//       }
//     };

//     fetchCatalogs();
//   }, []);

//   return (
//     <>
//     <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mx-4 sm:mx-8 md:mx-20 mt-52">
//       {cardsData.map((card) => (
//           <Link key={card.id} to={`/catalog/${card.id}`} className="card-link">
//           <div
//             className="relative bg-gray-200 w-full h-52 sm:h-54 md:h-80 lg:h-82 lg:w-50 ml-3 cursor-pointer"
//           >
//             <img src={card.imageUrl} className="object-cover w-full h-full" alt={card.title} />
//             <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
//               <h5
//                 style={{ fontFamily: 'Rosarivo' }}
//                 className="text-white text-center text-2xl pt-60"
//                 dangerouslySetInnerHTML={{ __html: card.title }}
//               ></h5>
//             </div>
//           </div>
//         </Link>
//       ))}
//     </div>
//     </>
//   );
// };

// export default Catalog;




  // const handleCardClick = (id) => {
  //   switch (id) {
  //     case 1:
  //       navigate('/catalog1');
  //       break;
  //     case 2:
  //       navigate('/catalog1');
  //       break;
  //     case 3:
  //       navigate('/catalog1');
  //       break;
  //     case 4:
  //       navigate('/catalog1');
  //       break;
  //     case 5:
  //       navigate('/catalog1');
  //       break;
  //     case 6:
  //       navigate('/catalog1');
  //       break;
  //     case 7:
  //       navigate('/catalog1');
  //       break;
  //     case 8:
  //       navigate('/catalog1');
  //       break;
  //     default:
  //       break;
  //   }
  // };